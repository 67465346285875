<template>
  <div class="app-container flex flex-v">
    <div class="searchBar bg-white" style="border: 1px solid white">
      <el-form ref="searchForm" :model="searchForm" label-width="80px" size="mini">
        <el-row :gutter="20">
          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
            <el-form-item label="分类编码">
              <el-input v-model="searchForm.codificationCode" clearable placeholder="分类编码"/>
            </el-form-item>
          </el-col>
          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
            <el-form-item label="分类名称">
              <el-input v-model="searchForm.codificationName" clearable placeholder="大类名称"/>
            </el-form-item>
          </el-col>
          <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
            <el-form-item style="text-align: right">
              <el-button
                  icon="el-icon-search"
                  type="primary"
                  size="mini"
                  @click="searchFormSubmit"
              >查 询
              </el-button>
              <el-button
                  icon="el-icon-plus"
                  type="primary"
                  plain
                  size="mini"
                  @click="addRecord"
              >新 增
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="bg-white main-content-wrapper margin-10 flex flex-1 flex-v">
      <el-table
        stripe
        class="flex-1"
        style="margin-top: 15px;"
        height="400px"
        v-loading="tableLoading"
        size="mini"
        :data="tableList"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :header-cell-style="{ background:'#f2f2f2' }"
      >
        <el-table-column label="分类编码" prop="codificationCode" />
        <el-table-column label="分类名称" prop="codificationName" align="center" />
        <el-table-column label="使用年限(月)" prop="serviceLifeMonth" align="center" />
        <el-table-column label="折旧科目" prop="depreciationAccountStr" align="center" />
        <el-table-column label="创建人" prop="createName" align="center" />
        <el-table-column label="创建日期" prop="createTime" align="center" show-overflow-tooltip />
        <el-table-column label="操作" align="right" min-width="120">
          <template slot-scope="scope">
            <el-button v-show="!scope.row.parentId" size="mini" type="text" @click="addChildRecord(scope.row)">添加子类</el-button>
            <el-button size="mini" type="text" @click="handleEditRecord(scope.row)">编辑</el-button>
            <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination pagi-sty">
        <!-- 分页-->
        <Pagination
          :page.sync="tablePage.currentPage"
          :total="tablePage.total"
          :limit.sync="tablePage.pageSize"
          @pagination="paginationChange"
        />
      </div>
    </div>
    <!-- 设置资产信息 -->
    <div class="dialog-content">
      <el-dialog
        :title="diaTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="32%"
        >
        <div>
          <el-form ref="cForm" :model="cForm" :rules="formRules" label-width="100px" size="mini">
            <el-row>
              <el-col :span="24">
                <el-form-item label="分类编码" prop="codificationCode">
                  <el-input v-model="cForm.codificationCode" @change="validateRepeat" placeholder="分类编码" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="分类名称" prop="codificationName">
                  <el-input v-model="cForm.codificationName" placeholder="分类名称" />
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="currentItem.parentId">
                <el-form-item label="使用年限(月)" prop="serviceLifeMonth">
                  <el-input v-model="cForm.serviceLifeMonth" placeholder="使用年限(月)" />
                </el-form-item>
              </el-col>
              <el-col :span="24" v-show="currentItem.parentId">
                <el-form-item label="折旧科目" prop="depreciationAccount">
                  <!-- <el-input v-model="cForm.depreciationAccount" placeholder="折旧科目" /> -->
                  <!-- :beforeClearAll="handleDepreClear" -->
                  <tree-select
                    size="mini"
                    v-model="cForm.depreciationAccount"
                    @select="handleTreeSelect"
                    :multiple="false"
                    :clearable="false"
                    :options="depreciationAccountList"
                    :disable-branch-nodes="true"
                    :defaultExpandLevel="2"
                    placeholder="请选择折旧科目"
                    :normalizer="normalizer"
                    noOptionsText="暂无数据"
                    noResultsText="暂无"
                    :limit="1"
                    :limitText="(count) => `+ ${count}`"
                    id="treeSh"
                    ></tree-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- <span>这是一段信息</span> -->
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="handleAddUpdate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import tables from '@/mixins/tables'
import { isFloat } from '@/utils/validate'
import assetService from '@/api/asset'
import TreeSelect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  // 资产大类
  name: 'asset-catefory-setting',
  components: { Pagination, TreeSelect },
  mixins: [tables],
  data() {
    return {
      searchForm: {
        codificationCode: '',
        codificationName: ''
      },
      cForm: {
        codificationCode: '',
        codificationName: '',
        serviceLifeMonth: '',
        depreciationAccount: null,
        depreciationAccountStr: null,
        parentId: null
      },
      diaTitle: '',
      dialogVisible: false,
      currentItem: {}, // 当前选中的记录
      operationType: '', // 操作类型 add/edit
      depreciationAccountList: [] // 折旧科目列表
    }
  },
  computed: {
    formRules() {
      const valiNum = (rule, value, callback) => {
        if (value && !isFloat(value)) {
          callback(new Error('请输入正确的数据(数字或小数)'))
        } else {
          callback()
        }
      }
      let rules = {
        codificationCode: [
          { required: true, message: '该字段不允许为空', trigger: 'change' }
        ],
        codificationName: [
          { required: true, message: '该字段不允许为空', trigger: 'change' }
        ],
        serviceLifeMonth: [
          { validator: valiNum, trigger: 'change' }
        ]
      }
      return rules
    }
  },
  mounted() {
    this.getCostConfigTree()
  },
  methods: {
    /**
     * ！！！必须方法，获取 list 接口
     *
     * @params {Object} data 请求数据：包含 searchForm 和 tablePage 属性
     * @return {Promise} Promise 获取 list 接口的异步方法
     */
    async getListApi(data) {
      return new Promise((resolve, reject) => {
        assetService.getAssetCateList(data).then(data => {
          resolve(data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 获取折旧科目
    getCostConfigTree() {
      assetService.getCostConfigTree({}).then(res => {
        this.depreciationAccountList = res.data
      }).catch(e => {
        console.log(e)
      })
    },
    // tree-select 设置
    normalizer(node, instanceId) {
      if (node.costConfigVOList == null || node.costConfigVOList == 'null') {
        delete node.costConfigVOList
      }
      return {
        id: node.costCode,
        label: node.name,
        children: node.costConfigVOList
      }
    },
    // 选择折旧科目
    handleTreeSelect(node) {
      this.cForm.depreciationAccount = node.costCode
      this.cForm.depreciationAccountStr = node.name
    },
    // 清空折旧科目
    // handleDepreClear() {
    //   this.cForm.depreciationAccount = null
    //   this.cForm.depreciationAccountStr = null
    // },
    // 新增分类
    addRecord() {
      this.operationType = 'add'
      this.diaTitle = '新增资产大类'
      this.dialogVisible = true
      this.clearDiaMsg()
    },
    // 新增子类
    addChildRecord(row) {
      this.operationType = 'add'
      this.diaTitle = '新增资产子类'
      this.dialogVisible = true
      this.cForm.codificationCode = ''
      this.cForm.codificationName = ''
      this.cForm.serviceLifeMonth = ''
      this.cForm.depreciationAccount = null
      this.cForm.depreciationAccountStr = null
      this.cForm.parentId = row.id
      this.currentItem = JSON.parse(JSON.stringify(row))
      this.currentItem.parentId = row.id
    },
    // 校验分类编码重复
    validateRepeat() {
      // let obj = {
      //   codificationCode: this.cForm.codificationCode,
      //   parentId: this.currentItem.parentId || null
      // }
      // checkAssetRepeat(obj).then(res => {

      // }).catch(e => {
      //   console.log(e)
      // })
    },
    // 修改
    handleEditRecord(row) {
      this.operationType = 'edit'
      this.diaTitle = '修改资产分类'
      this.dialogVisible = true
      this.cForm.codificationCode = row.codificationCode || ''
      this.cForm.codificationName = row.codificationName || ''
      this.cForm.serviceLifeMonth = row.serviceLifeMonth || ''
      this.cForm.depreciationAccount = row.depreciationAccount || null
      this.cForm.depreciationAccountStr = row.depreciationAccountStr || null
      this.currentItem = row
    },
    // 新增或者更新车辆档案
    handleAddUpdate() {
      this.$refs.cForm.validate(result => {
        if (result) {
          if (this.operationType === 'add') {
            this.addRecordService()
          } else if (this.operationType === 'edit') {
            this.updateRecordService()
          }
        }
      })
    },
    // 新增服务
    addRecordService() {
      assetService.addAssetCate(this.cForm).then(res => {
        this.$message.success('操作成功')
        this.dialogVisible = false
        this.clearDiaMsg()
        this.searchFormSubmit()
      }).catch(e => {
        console.log(e)
      })
    },
    // 更新服务
    updateRecordService() {
      this.currentItem.codificationCode = this.cForm.codificationCode
      this.currentItem.codificationName = this.cForm.codificationName
      this.currentItem.serviceLifeMonth = this.cForm.serviceLifeMonth
      this.currentItem.depreciationAccount = this.cForm.depreciationAccount || null
      this.currentItem.depreciationAccountStr = this.cForm.depreciationAccountStr || null
      assetService.updateAssetCate(this.currentItem).then(res => {
        this.$message.success('操作成功')
        this.dialogVisible = false
        this.clearDiaMsg()
        this.searchFormSubmit()
      }).catch(e => {
        console.log(e)
      })
    },
    // 删除
    handleDelete(row) {
      this.$confirm(`确定要删除吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let postData = {
          ids: [row.id],
          parentId: row.parentId || ''
        }
        assetService.deleteAssetCate(postData).then(res => {
          this.$message.success('操作成功')
          this.searchFormSubmit()
        }).catch(e => {
          console.log(e)
        })
      }).catch(() => {})
    },
    // 清空输入框
    clearDiaMsg() {
      this.cForm = {
        codificationCode: '',
        codificationName: '',
        serviceLifeMonth: '',
        depreciationAccount: null,
        depreciationAccountStr: null,
        parentId: null
      }
      this.currentItem = {}
    }
  }
}
</script>

<style scoped lang="scss">
.app-container {
  background-color: #f6f6f6;
  height: 100%;
}

.icon {
  font-size: 16px;
  line-height: 0 !important;
}

::v-deep .el-pagination__total {
  float: right;
}

::v-deep .el-tooltip__popper {
  line-height: 0.5;
}

.el-form {
  background: #FFFFFF;
  padding: 20px 20px 0 20px;
  margin: 10px;
  .el-col {
    padding-left: 20px;
    padding-right: 20px;
    ::v-deep .el-form-item__label {
      font-size: 12px;
    }
    .el-input {
      width: 240px;
    }
  }
}
::v-deep .el-table__header-wrapper .el-checkbox {
  right: 4px;
}
.pagi-sty {
  width: 100%;
  padding-top: 20px;
  text-align: right;
}
.dialog-content {
  ::v-deep .vue-treeselect__control {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding-left: 12px;
    width: 240px;
  }
  ::v-deep .vue-treeselect__placeholder {
    top: -2px;
  }
  ::v-deep .vue-treeselect__single-value {
    top: -2px;
  }
}
</style>
