import { getRequest, postRequest } from "@/utils/request_utils"

export default {
  // 资产大类--查询列表
  getAssetCateList: (data)=> {
    return postRequest(`/api-base/assetCategories/query`,{data});
  },
  // 资产大类--新增单条
  addAssetCate: (data)=> {
    return postRequest(`/api-base/assetCategories/add`,{data});
  },
  // 资产大类--删除单条
  deleteAssetCate: (data)=> {
    return postRequest(`/api-base/assetCategories/removeByIds`,{data});
  },
  // 资产大类--分类编码查重
  updateCarArchives: (data)=> {
    return getRequest(`/api-base/assetCategories/existCodificationCode`,{data});
  },
  // 资产大类--修改单条
  updateAssetCate: (data)=> {
    return postRequest(`/api-base/assetCategories/updateById`,{data});
  },
  // 查询费用树
  getCostConfigTree: (data)=> {
    return postRequest(`/api-base/other/selectCompanyAccountInfoByCode`,{data});
  }
}
